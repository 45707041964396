<template>
  <v-card
    flat
    class="mt-5"
  >
    <v-form
      ref="form"
      v-model="valid"
      @submit.prevent="onSubmit"
    >
      <div class="px-3">
        <v-card-text class="pt-5">
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
            >
              <v-autocomplete
                v-model="paymentLinkDataLocal.shopId"
                :items="shopFilterItems"
                :rules="[validators.required]"
                placeholder="Select Shop"
                :loading="loading"
                item-text="text"
                item-value="value"
                outlined
                dense
                clearable
                hide-details="auto"
                class="mt-3"
              >
                <template #label>
                  <span class="red--text"><strong>* </strong></span>Shop
                </template>
              </v-autocomplete>

              <v-text-field
                v-model="paymentLinkDataLocal.firstName"
                outlined
                dense
                :rules="[validators.required]"
                placeholder="First Name"
                hide-details="auto"
                class="mt-3"
              >
                <template #label>
                  <span class="red--text"><strong>* </strong></span>First Name
                </template>
              </v-text-field>

              <v-text-field
                v-model="paymentLinkDataLocal.lastName"
                :rules="[validators.required]"
                outlined
                dense
                placeholder="Last Name"
                hide-details="auto"
                class="mt-3"
              >
                <template #label>
                  <span class="red--text"><strong>* </strong></span>Last Name
                </template>
              </v-text-field>

              <v-text-field
                v-model="paymentLinkDataLocal.personalId"
                outlined
                dense
                placeholder="Personal ID"
                hide-details="auto"
                class="mt-3"
                label="Personal ID"
              >
              </v-text-field>

              <v-text-field
                v-model="paymentLinkDataLocal.email"
                :rules="[validators.required, validators.emailValidator]"
                outlined
                dense
                type="email"
                placeholder="E-mail"
                hide-details="auto"
                class="mt-3"
              >
                <template #label>
                  <span class="red--text"><strong>* </strong></span>E-mail
                </template>
              </v-text-field>

              <v-text-field
                v-model="paymentLinkDataLocal.phone"
                outlined
                dense
                type="phone"
                placeholder="Phone"
                label="Phone"
                hide-details="auto"
                class="mt-3"
              >
              </v-text-field>

              <v-text-field
                v-model="paymentLinkDataLocal.address"
                outlined
                dense
                placeholder="Address"
                label="Address"
                hide-details="auto"
                class="mt-3"
              >
              </v-text-field>

              <v-text-field
                v-model="paymentLinkDataLocal.city"
                outlined
                dense
                placeholder="City"
                label="City"
                hide-details="auto"
                class="mt-3"
              >
              </v-text-field>

              <v-text-field
                v-model="paymentLinkDataLocal.postcode"
                outlined
                dense
                placeholder="Postcode"
                label="Postcode"
                hide-details="auto"
                class="mt-3"
              >
              </v-text-field>

              <v-select
                v-model="paymentLinkDataLocal.country"
                :loading="loading"
                :rules="[validators.required]"
                placeholder="Country"
                :items="countryFilterItems"
                item-text="text"
                item-value="value"
                outlined
                dense
                clearable
                hide-details="auto"
                class="mt-3"
              >
                <template #label>
                  <span class="red--text"><strong>* </strong></span>Country
                </template>
              </v-select>

              <v-text-field
                v-model="paymentLinkDataLocal.description"
                outlined
                dense
                label="Description"
                placeholder="Description"
                hide-details="auto"
                class="mt-3"
              ></v-text-field>

              <v-text-field
                v-model="paymentLinkDataLocal.amount"
                outlined
                dense
                :rules="[validators.required, validators.decimalValidator]"
                type="number"
                placeholder="Amount"
                hide-details="auto"
                class="mt-3"
              >
                <template #label>
                  <span class="red--text"><strong>* </strong></span>Amount
                </template>
              </v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="4"
              md="6"
              class="d-none d-sm-flex justify-center position-relative"
            >
              <v-img
                contain
                max-width="170"
                src="@/assets/images/3d-characters/pose-m-1.png"
                class="security-character"
              ></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </div>

      <!-- divider -->
      <v-divider></v-divider>

      <div class="pa-3">
        <!-- action buttons -->
        <v-card-text>
          <v-btn
            color="primary"
            type="submit"
            class="me-3 mt-3"
            :disabled="!valid"
            :loading="loading"
          >
            Generate Payment Link
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            class="mt-3"
            @click="resetForm"
          >
            Reset
          </v-btn>
        </v-card-text>
      </div>
    </v-form>
  </v-card>
</template>

<script>
import {
  onMounted, watch, ref, toRefs, reactive,
} from '@vue/composition-api'
import { mdiClose, mdiKeyOutline, mdiHelpCircleOutline } from '@mdi/js'
import {
  required,
  emailValidator,
  decimalValidator,
} from '@core/utils/validation'
import store from '@/store'

export default {
  props: {
    paymentLinkData: reactive({
      type: Object,
      required: true,
    }),
  },
  setup(props, { emit }) {
    const paymentLinkDataLocal = toRefs(props).paymentLinkData

    watch(props.paymentLinkData, value => {
      paymentLinkDataLocal.value = value
    })

    const loading = ref(false)

    const valid = ref(false)
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const shopFilterItems = ref([])
    const countryFilterItems = ref([])
    const isDialogVisible = ref(false)

    const generatePaymentLink = () => {
      loading.value = true
      store
        .dispatch('processing-payment-links/add', paymentLinkDataLocal.value)
        .then(response => {
          emit('payment-link-generated', response.data)
        })
        .catch(e => {
          const response = JSON.parse(e.request.response)
          emit('payment-link-error', response.errors)
        })
        .finally(() => {
          loading.value = false
        })
    }

    const onSubmit = () => {
      if (valid.value) {
        generatePaymentLink()
      } else {
        validate()
      }
    }

    const fetchConfig = () => {
      loading.value = true
      store
        .dispatch('processing-payment-links/fetchConfig')
        .then(response => {
          const config = response.data
          shopFilterItems.value = config.shops
          countryFilterItems.value = config.countries
        })
        .catch(error => error)
        .finally(() => {
          loading.value = false
        })
    }

    onMounted(() => {
      fetchConfig()
    })

    return {
      loading,
      shopFilterItems,
      countryFilterItems,
      isDialogVisible,
      valid,
      paymentLinkDataLocal,
      onSubmit,
      resetForm,
      generatePaymentLink,
      form,

      // validation
      validators: { required, emailValidator, decimalValidator },
      icons: {
        mdiClose,
        mdiKeyOutline,
        mdiHelpCircleOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.two-factor-auth {
  max-width: 25rem;
}

.security-character {
  position: absolute;
  bottom: -0.5rem;
}

.red--text{
  color: red;
}
</style>
