import { ref } from '@vue/composition-api'
import { mdiFileLinkOutline, mdiLinkVariant, mdiClose } from '@mdi/js'

export default function usePaymentLink() {
  const snackbar = ref({
    isSnackbarVisible: false,
    snackBarColor: 'info',
    snackbarMessage: '',
    snackbarTimeOut: '3000',
  })

  const tab = ref('')

  // tabs
  const tabs = [
    { title: 'Payment Link Details', icon: mdiFileLinkOutline },
    { title: 'Result', icon: mdiLinkVariant },
  ]

  const paymentLinkData = ref({})
  const paymentLinkGenerated = ref({})
  const loading = ref(false)

  const onPaymentLinkGenerated = data => {
    paymentLinkGenerated.value = data
    snackbar.value.isSnackbarVisible = true
    snackbar.value.snackBarColor = 'info'
    snackbar.value.snackbarMessage = `Your payment link ${paymentLinkGenerated.value.paymentLinkCode} has been created successfully!`
    tab.value = 1
  }

  const onPaymentLinkCopied = () => {
    snackbar.value.isSnackbarVisible = true
    snackbar.value.snackBarColor = 'info'
    snackbar.value.snackbarMessage = 'Your payment link has been copied to your clipboard!'
  }

  const onPaymentLinkError = e => {
    snackbar.value.isSnackbarVisible = true
    snackbar.value.snackBarColor = 'error'

    let msg = ''
    e.errors.forEach(element => {
      msg += `<li>${element.message}</li>`
    })

    snackbar.value.snackbarMessage = `Error: ${msg}`
  }

  return {
    tab,
    tabs,
    paymentLinkData,
    snackbar,
    loading,
    paymentLinkGenerated,
    onPaymentLinkGenerated,
    onPaymentLinkError,
    onPaymentLinkCopied,
    icons: {
      mdiClose,
    },
  }
}
