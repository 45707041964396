import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchConfig() {
      return new Promise((resolve, reject) => {
        axios
          .get('/processing/page-setups/payment-links/add')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/processing/payment-links', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/processing/payment-links/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    add(ctx, model) {
      return new Promise((resolve, reject) => {
        axios
          .post('/processing/payment-links', model)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    sendByEmail(ctx, paymentLinkCode) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/processing/payment-links/${paymentLinkCode}/send-by-email`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
