<template>
  <v-card
    flat
    class="mt-5"
  >
    <v-form ref="form">
      <div class="px-3">
        <v-card-text class="pt-5">
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-model="paymentLinkGeneratedLocal.paymentLinkUrl"
                readonly
                outlined
                dense
                hide-details
                :append-icon="icons.mdiContentCopy"
                @click:append="copyPaymentLinkUrl"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="4"
              md="6"
              class="d-none d-sm-flex justify-center position-relative"
            >
            </v-col>
          </v-row>
        </v-card-text>
      </div>

      <!-- divider -->
      <v-divider></v-divider>

      <div class="pa-3">
        <v-card-text
          v-if="paymentLinkGeneratedLocal != null && paymentLinkGeneratedLocal.paymentLinkUrl != null"
          class="payment-link-details text-center mx-auto"
        >
          <v-avatar
            color="primary"
            class="v-avatar-light-bg primary--text mb-4"
            rounded
          >
            <v-icon
              size="25"
              color="primary"
            >
              {{ icons.mdiLinkVariant }}
            </v-icon>
          </v-avatar>
          <p class="text-base text--primary font-weight-semibold">
            Your payment link is ready to be used for:
          </p>
          <p class="text-base text--primary">
            {{ paymentLinkDataLocal.firstName }} {{ paymentLinkDataLocal.lastName }} ({{
              paymentLinkDataLocal.country
            }})
          </p>
          <p class="text-base text--primary">
            {{ paymentLinkDataLocal.email }}
          </p>
          <p class="text-base text--primary">
            {{ paymentLinkDataLocal.amount }} USD
          </p>
          <p class="text-sm text--primary">
            You can either copy it or send it by e-mail by clicking on the
            button below
          </p>

          <v-btn
            color="primary"
            class="mt-3"
            :loading="loading"
            :disabled="paymentLinkGeneratedLocal.paymentLinkUrl == null"
            @click="sendByEmail"
          >
            Send By E-mail
          </v-btn>

          <v-btn
            color="secondary"
            outlined
            class="ml-3 mt-3"
            @click="createNewPaymentLink"
          >
            Create New
          </v-btn>
        </v-card-text>

        <v-card-text
          v-if="paymentLinkGeneratedLocal == null || paymentLinkGeneratedLocal.paymentLinkUrl == null"
          class="payment-link-details text-center mx-auto"
        >
          <v-avatar
            color="primary"
            class="v-avatar-light-bg primary--text mb-4"
            rounded
          >
            <v-icon
              size="25"
              color="primary"
            >
              {{ icons.mdiAlertCircleOutline }}
            </v-icon>
          </v-avatar>
          <p class="text-base text--primary font-weight-semibold">
            Generate a Payment Link in the previous step before continuing
          </p>
        </v-card-text>
      </div>
    </v-form>
    <v-dialog
      v-model="isDialogVisible"
      persistent
      width="500"
    >
      <v-card>
        <v-card-title>
          The payent link has been sent to: <strong>{{ paymentLinkDataLocal.email }}</strong>
        </v-card-title>
        <v-card-text>Would you like to create a new Payment Link?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            :disabled="loading"
            @click="isDialogVisible = false"
          >
            No
          </v-btn>
          <v-btn
            color="success"
            :loading="loading"
            :disabled="loading"
            @click="createNewPaymentLink"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiLinkVariant, mdiContentCopy, mdiEmailOutline, mdiAlertCircleOutline } from '@mdi/js'
import {
  ref, toRefs, reactive, watch,
} from '@vue/composition-api'
import store from '@/store'

export default {
  props: {
    paymentLinkGenerated: reactive({
      type: Object,
      required: true,
    }),
    paymentLinkData: reactive({
      type: Object,
      required: true,
    }),
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const paymentLinkGeneratedLocal = toRefs(props).paymentLinkGenerated
    const paymentLinkDataLocal = toRefs(props).paymentLinkData
    const form = ref(null)

    const isDialogVisible = ref(false)

    const copyPaymentLinkUrl = () => {
      if (paymentLinkGeneratedLocal.value.paymentLinkUrl == null) return

      const url = paymentLinkGeneratedLocal.value.paymentLinkUrl
      navigator.clipboard.writeText(url)
      emit('payment-link-copied', url)
    }

    const sendByEmail = () => {
      loading.value = true
      store
        .dispatch('processing-payment-links/sendByEmail', paymentLinkGeneratedLocal.value.paymentLinkCode)
        .then(() => {
          isDialogVisible.value = true
        })
        .catch(e => {
          const response = JSON.parse(e.request.response)
          emit('payment-link-sent-error', response.errors)
        })
        .finally(() => {
          loading.value = false
        })
    }

    const onReset = () => {
      form.value.reset()
    }

    const createNewPaymentLink = () => {
      emit('create-new-payment-link')
      form.value.reset()
      isDialogVisible.value = false
    }

    watch(props.paymentLinkGenerated, value => {
      paymentLinkGeneratedLocal.value = value // Update when the prop changes
    })

    watch(props.paymentLinkData, value => {
      paymentLinkDataLocal.value = value // Update when the prop changes
    })

    return {
      copyPaymentLinkUrl,
      sendByEmail,
      createNewPaymentLink,
      onReset,
      form,
      paymentLinkGeneratedLocal,
      paymentLinkDataLocal,
      isDialogVisible,
      loading,
      icons: {
        mdiLinkVariant,
        mdiContentCopy,
        mdiEmailOutline,
        mdiAlertCircleOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.payment-link-details {
  max-width: 25rem;
}
</style>
