<template>
  <v-card id="account-setting-card">
    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.icon"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <payment-link-details
          ref="paymentLinkDetails"
          :payment-link-data="paymentLinkData"
          @payment-link-generated="onPaymentLinkGenerated"
        ></payment-link-details>
      </v-tab-item>

      <v-tab-item>
        <payment-link-result
          :payment-link-generated="paymentLinkGenerated"
          :payment-link-data="paymentLinkData"
          @payment-link-copied="onPaymentLinkCopied"
          @create-new-payment-link="$refs.paymentLinkDetails.resetForm();tab = 0"
        >
        </payment-link-result>
      </v-tab-item>
    </v-tabs-items>
    <!-- snackbar -->
    <v-snackbar
      v-model="snackbar.isSnackbarVisible"
      :timeout="2000"
    >
      <span v-html="snackbar.snackbarMessage"></span>
      <template v-slot:action="{ attrs }">
        <v-icon
          size="20"
          @click="snackbar.isSnackbarVisible = false"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import { onMounted, onUnmounted, ref } from '@vue/composition-api'
import PaymentLinkDetails from './PaymentLinkDetails.vue'
import PaymentLinkResult from './PaymentLinkResult.vue'
import storeModule from '../storeModule'
import store from '@/store'
import usePaymentLink from './usePaymentLink'

export default {
  components: {
    PaymentLinkDetails,
    PaymentLinkResult,
  },
  setup() {
    const STORE_MODULE_NAME = 'processing-payment-links'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
      }
    })

    const {
      paymentLinkData,
      tab,
      tabs,
      snackbar,
      loading,
      paymentLinkGenerated,
      onPaymentLinkGenerated,
      onPaymentLinkError,
      onPaymentLinkCopied,
      icons,
    } = usePaymentLink()

    return {
      tab,
      tabs,
      paymentLinkData,
      snackbar,
      loading,
      paymentLinkGenerated,
      onPaymentLinkGenerated,
      onPaymentLinkError,
      onPaymentLinkCopied,
      icons,
    }
  },
}
</script>
